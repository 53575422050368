import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { getDocuments } from '../../services';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';

const AppDocuments = () => {
    const [documents, setDocuments] = useState([]);
    const [spinner, setSpinner] = useState(false);

    const doLogout = async () => {
        this.props.dispatch({ type: 'USER', payload: {} });
        localStorage.removeItem('token');
        window.location.href = '/admin';
    };

    const getDocumentList = async () => {
        setSpinner(true)
        try {
            const response = await getDocuments();
            if (response) {
                if (response.status === 200) {
                    // console.log("res", response)
                    setDocuments(response?.data)
                } else if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                    toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                    doLogout();
                } else if (response.response.status >= 500) {
                    toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
                }
            }

        } catch (error) {
            console.log(error)
        } finally {
            setSpinner(false);
        }
    }

    useEffect(() => {
        getDocumentList()
    }, [])


    return (
        <>
            <div className='container mt-5' style={{ maxWidth: '900px' }}>
                <Spinner display={spinner}>
                    {documents?.length > 0 ?
                        <div className='card'>
                            <div className='card-header'>
                                <h3 className='mb-0'>Documents</h3>
                            </div>
                            <div className='card-body py-0'>
                                <ul className='list-group list-group-flush'>
                                    {documents?.map((document, index) => {
                                        return (
                                            <li className='list-group-item py-3' key={document?.id}>
                                                <div className='row align-items-center'>
                                                    <div className='col-auto'>
                                                        <img src={`/img/${document?.type?.toLowerCase()}.png`} alt='...' className='avatar-img rounded' style={{ width: '24px', height: '24px' }} />
                                                    </div>
                                                    <div className='col ms-n2'>
                                                        {document?.name}
                                                    </div>
                                                    <div className='col-auto'>
                                                        <button onClick={() => { window.open(document.file_path, '_blank'); toast.success(`${document?.name} downloaded successfully`) }} className='btn btn-sm  btn-light mx-3'>
                                                            <i className='fe fe-download me-2'></i> Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>

                            </div>
                        </div> :
                        (
                            <div className="card">
                                <div className="m-5 text-center">
                                    <div className="row">
                                        <div className="col-lg-4 offset-lg-4 text-center">
                                            <img src="/img/empty.svg" className="img-fluid" alt="no-data-found" />
                                            <p className="mt-3">Looks like you don't have any Documents!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Spinner>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(AppDocuments);
