import React from 'react';
import AppNavigation from './AppNavigation';

class AppLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <AppNavigation />
        <div className="main-content">{children}</div>
      </>
    );
  }
}

export default AppLayout;
