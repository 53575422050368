import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import { Link, withRouter } from 'react-router-dom';

class AdminNavigation extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  componentDidMount() {
    const { user, history } = this.props;
    if (isEmpty(user)) {
      history.push('/admin');
    }
  }

  doLogout = async () => {
    const { dispatch } = this.props;
    await dispatch({ type: 'USER', payload: {} });
    window.location.href = '/admin';
  };

  onIdle = async (e) => {
    const { dispatch } = this.props;
    await dispatch({ type: 'USER', payload: {} });
    window.location.href = '/admin';
  };
  render() {
    const { user } = this.props;
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={10800000}
        />

        <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/admin/dashboard" className="navbar-brand">
              <img src="/img/logo.png" className="navbar-brand-img mx-auto pr-5" alt="logo" />
            </Link>
            {!user.specialRole && (
              <span style={{ maxWidth: '100px', fontWeight: '800' }} className="badge badge-soft-success ml-2">
                {!isEmpty(user) && user.roles.length > 0 && user.roles.includes('admin') ? 'ADMIN' : 'SUPER-ADMIN'}
              </span>
            )}
            {user.specialRole && (
              <span style={{ maxWidth: '100px', fontWeight: '800' }} className="badge badge-soft-info ml-2">
                {!isEmpty(user) && user.roles.length > 0 && user.roles.includes('admin') ? 'ADMIN-VIEW' : 'SUPER-ADMIN-VIEW'}
              </span>
            )}
            <div className="collapse navbar-collapse" id="sidebarCollapse">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/admin/dashboard" className="nav-link" role="button">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/quotes" className="nav-link" role="button">
                    <i className="fe fe-file"></i> Quotations
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/documents" className="nav-link" role="button">
                    <i className="fe fe-file-text"></i> Documents
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/users" className="nav-link" role="button">
                    <i className="fe fe-users"></i>Users
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin" className="nav-link" role="button" onClick={() => this.doLogout()}>
                    <i className="fe fe-log-out"></i>Sign Out
                  </Link>
                </li>
              </ul>
              <div className="mt-auto"></div>
            </div>
          </div>
        </nav>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(AdminNavigation));
