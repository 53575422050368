import React from 'react';
import { filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import { getQuotesByUser } from '../../services';
import { trackPageView, trackEvent } from '../../helpers/analytics';

const moment = require('moment');
const cartHelper = require('../../helpers/appHelper');

class Quotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quotes: [],
      currentPage: 1,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getQuoteList();
    trackPageView();
  };

  getQuoteList = async () => {
    const { user, dispatch, history } = this.props;
    const { id } = user;
    this.setState({ loading: true });
    try {
      const response = await getQuotesByUser(id);
      if (response) {
        if (response.status === 200) {
          const quoteList = response.data;
          this.setState({ quotes: quoteList || [], loading: false });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      console.warn(error);
    }
  };

  download = async (quote) => {
    const { user } = this.props;
    const quoteDate = quote ? moment(quote.created_on).format('LLL') : '';
    const quoteValidity = quote ? moment(quote.created_on).add(90, 'days').format('LLL') : '';
    // await cartHelper.customerDownloadQuote(quote.products, user, quote.id, quote.address, quote.freight, quoteDate, quote.request_type, quoteValidity);
    await cartHelper.customerDownloadQuote(quote.products, user, quote.id, quote.address, 0, quoteDate, '', quoteValidity);
    trackEvent('Quotes', 'Download', quote.id);
  };

  repeat = async (quote) => {
    const { user, cart, dispatch, history } = this.props;
    const { id } = user;
    const filteredCart = filter(cart, (item) => item.userId !== id);
    let Updatedcart = [];
    for (let i = 0; i < quote.products.length; i++) {
      let product = quote.products[i];
      product.asked_price = 0;
      product.approved_price = 0
      product.approved_price_comment = ""
      product.userId = id;
      product.reason = '';
      product.sub_total = product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
      Updatedcart.push(product);
    }
    const finalCart = [...filteredCart, ...Updatedcart];
    dispatch({ type: 'CART', payload: finalCart });
    trackEvent('Quotes', 'Repeat', quote.id);
    history.push('/cart');
  };

  nextPage = () => {
    let page = this.state.currentPage + 1;
    this.setState({ currentPage: page });
  };

  prevPage = () => {
    let page = this.state.currentPage;
    if (page > 1) {
      page = page - 1;
      this.setState({ currentPage: page });
    }
  };

  render() {
    const { quotes, loading, currentPage } = this.state;
    let lastindex = currentPage * 20;
    let firstindex = lastindex - 20;
    const cartQuotes = quotes && quotes.slice(firstindex, lastindex);
    return (
      <Spinner display={loading}>
        {quotes.length > 0 ? (
          <div className="container mt-5" style={{ maxWidth: '900px' }}>
            <div className="card">
              <div className="table-responsive">
                <table className="table table-sm table-nowrap card-table">
                  <thead>
                    <tr>
                      <th>Quote Request #</th>
                      <th>Date</th>
                      <th>Total Cost</th>
                      <th className="text-center">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="list">
                    {cartQuotes.map((quote, index) => (
                      <tr key={index}>
                        <td>{quote.id}</td>
                        <td>{moment(quote.created_on).format('ll')}</td>
                        <td>
                          <Amount value={quote.total} />
                        </td>
                        <td className="text-center">
                          <h4>
                            <span className={quote.status === 'Open-Closed' ? 'badge badge-soft-success ml-2' : quote.status === 'Open' ? 'badge badge-soft-warning ml-2' : ''}>{quote.status === 'Open' ? 'Pending Quote' : quote.status === 'Open-Closed' ? 'Final Quote' : '-'}</span>
                          </h4>
                        </td>
                        <td>
                          <button className="btn btn-sm btn-light mx-1" onClick={() => this.download(quote)}>
                            <i className="fe fe-download"></i> Download
                          </button>
                          <button className="btn btn-sm btn-success mx-1" onClick={() => this.repeat(quote)}>
                            <i className="fe fe-refresh-cw"></i> Repeat Order
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Pagination */}

            <ul className="pagination pagination-sm justify-content-center">
              {cartQuotes.length > 15 && (
                <li className={this.state.currentPage > 1 || cartQuotes.length === 0 ? 'page-item' : 'page-item disabled'}>
                  <div className="page-link" href="#!" onClick={this.prevPage} style={{ cursor: 'pointer' }}>
                    Previous
                  </div>
                </li>
              )}
              {cartQuotes.length > 15 && (
                <li className="page-item disabled">
                  <div className="page-link" href="#!">
                    Page {this.state.currentPage}
                  </div>
                </li>
              )}
              {cartQuotes.length > 15 && (
                <li className={cartQuotes.length < 20 ? 'page-item disabled' : 'page-item'}>
                  <div className="page-link" href="#!" onClick={this.nextPage} style={{ cursor: 'pointer' }}>
                    Next
                  </div>
                </li>
              )}
            </ul>
          </div>
        ) : (
          <div className="m-5 text-center">
            <div className="row">
              <div className="col-lg-4 offset-lg-4 text-center">
                <img src="/img/empty_orders.svg" className="img-fluid" alt="no-data-found" />
                <p className="mt-3">
                  Looks like you have not placed any order yet! Add <Link to="/products">products</Link> to cart to place your first order.
                </p>
              </div>
            </div>
          </div>
        )}
      </Spinner>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
  };
};

export default connect(mapStateToProps)(Quotes);
