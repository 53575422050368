import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

const getAllUsers = async () => {
  try {
    const URL = BASE_URL.concat(`/user`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getUserById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateUserById = async (id, user) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}`);
    const data = await axios.post(URL, user);
    return data;
  } catch (err) {
    return err;
  }
};

const activateUser = async (id, status) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/enable`);
    const data = await axios.post(URL, status);
    return data;
  } catch (err) {
    return err;
  }
};

const deactivateUser = async (id, status) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/disable`);
    const data = await axios.post(URL, status);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteUserById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getProducts = async (keyword, take, skip) => {
  try {
    const URL = BASE_URL.concat(`/product?keyword=${keyword.toLowerCase().trim()}&take=${take}&skip=${skip}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getProductsById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/product?id=${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const updateProductById = async (id, product) => {
  try {
    const URL = BASE_URL.concat(`/product/${id}`);
    const data = await axios.post(URL, product);
    return data;
  } catch (err) {
    return err;
  }
};

const createQuote = async (quote) => {
  try {
    const URL = BASE_URL.concat('/quote ');
    const data = await axios.post(URL, quote);
    return data;
  } catch (err) {
    return err;
  }
};

const updateQuoteById = async (id, quote) => {
  try {
    const URL = BASE_URL.concat(`/quote/${id}`);
    const data = await axios.post(URL, quote);
    return data;
  } catch (err) {
    return err;
  }
};

const getAllQuotes = async () => {
  try {
    const URL = BASE_URL.concat('/quote');
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getQuotesByUser = async (id) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/quote`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getQuoteById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/quote/${id}`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const addAddress = async (id, address) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/address`);
    const data = await axios.post(URL, address);
    return data;
  } catch (err) {
    return err;
  }
};

const getAddressById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/address`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteAddress = async (id, addressId) => {
  try {
    const URL = BASE_URL.concat(`/user/${id}/address/${addressId}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDistributors = async (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  try {
    const URL = BASE_URL.concat(`/distributor`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getDocuments = async () => {
  try {
    const URL = BASE_URL.concat(`/document`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const deleteDocumentById = async (id) => {
  try {
    const URL = BASE_URL.concat(`/document/${id}`);
    const data = await axios.delete(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const getAllBussinessUnit = async () => {
  try {
    const URL = BASE_URL.concat(`/business-unit`);
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const endorseSuperAdmin = async (id, notification) => {
  try {
    const URL = BASE_URL.concat(`/quote/${id}/endros`);
    const data = await axios.post(URL, notification);
    return data;
  } catch (err) {
    return err;
  }
};

const updateDistributorById = async (id, distributor) => {
  try {
    const URL = BASE_URL.concat(`/distributor/${id}`);
    const data = await axios.get(URL, distributor);
    return data;
  } catch (err) {
    return err;
  }
};

const getLastApprovedPrice = async (product_id, user_id) => {
  try {
    const URL = BASE_URL.concat(`/quote/${product_id}/${user_id}`)
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
}

const uploadFile = async (file, file_name) => {
  try {
    const URL = BASE_URL.concat(`/document/${file_name}/upload`)
    const data = await axios.post(URL, file);
    return data;
  } catch (err) {
    return err;
  }
}

const createDocument = async (document) => {
  try {
    const URL = BASE_URL.concat(`/document`);
    const data = await axios.post(URL, document);
    return data;
  } catch (err) {
    return err;
  }
};

export { activateUser, deactivateUser, endorseSuperAdmin, getAllBussinessUnit, getProductsById, updateProductById, getAllUsers, getUserById, getQuoteById, updateQuoteById, getAddressById, addAddress, deleteAddress, getProducts, getDistributors, getDocuments, getQuotesByUser, getAllQuotes, updateUserById, createQuote, deleteUserById, updateDistributorById, getLastApprovedPrice, deleteDocumentById, uploadFile, createDocument };
