import React, { PureComponent } from 'react';
import 'firebase/auth';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/Spinner';
import { getDistributors } from '../../services';
import { auth, verifyApi } from '../../services/authServices';
import { Link, withRouter } from 'react-router-dom';
import { errors } from '../../admin/constants/Constants';
import SimpleReactValidator from 'simple-react-validator';
import { trackPageView, trackEvent, trackException } from '../../helpers/analytics';

toast.configure();

class AppLogin extends PureComponent {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        email: 'Please enter a valid email',
        default: 'This field is required',
      },
    });
    this.state = {
      email: '',
      password: '',
      loading: false,
      isVerified: true,
      showPassword: false,
    };
  }

  async componentDidMount() {
    const { user, history } = this.props;
    await this.checkStatus();
    if (isEmpty(user)) {
      history.push('/');
    } else {
      if (user.roles.length > 0 && user.roles.includes('user') && user.site === 'app') {
        history.push('/products');
      }
    }
    trackPageView();
  }

  checkStatus = async () => {
    try {
      const response = await verifyApi();
      if (response && response.status === 200) {
        this.setState({ isVerified: true });
      } else {
        this.setState({ isVerified: false });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onShowPassword = () => {
    const { password, showPassword } = this.state;
    if (password) {
      this.setState({ showPassword: !showPassword });
    }
  };

  syncDistributors = async (user) => {
    const { dispatch } = this.props;
    try {
      const response = await getDistributors(user.token);
      if (response && response.status === 200) {
        const distributor = response.data.find((item) => item.id === user.distributor_id || item.name === user.company);
        dispatch({ type: 'DISTRIBUTORS', payload: distributor });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  onSubmit = async (event) => {
    const { email, password } = this.state;
    const { dispatch, history } = this.props;
    event.preventDefault();
    if (email && password) {
      this.setState({ loading: true });
      const request = { email: email.toLowerCase().trim(), password: password };
      const response = await auth(request);
      try {
        if (response) {
          if (response.status === 200 || response.status === 201) {
            let user = response.data;
            const fixedUserData = { ...response.data };
            await localStorage.setItem('token', fixedUserData.token);
            user.site = 'app';
            if (user.roles.length > 0 && user.roles.includes('user')) {
              await dispatch({ type: 'USER', payload: user });
              await this.syncDistributors(user);
              trackEvent('Sign In', 'Success');
              await window.location.reload();
              setTimeout(() => {
                history.push('/products');
              }, 1500);
            } else {
              toast.error(errors.ACCESS_DENIED);
              this.setState({ loading: false });
              trackException('User access denied. Please contact admin.', false);
              trackEvent('Sign In', 'Error');
            }
          } else {
            const message = response.response.data.error;
            toast.error(message);
            this.setState({ loading: false });
          }
        }
      } catch (error) {
        this.setState({ loading: false });
        console.warn(error);
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      event.preventDefault();
    }
  };

  render() {
    const { isVerified } = this.state;
    return (
      <div className="d-flex align-items-center vh-100 bg-merck-purple text-white">
        <div className="container">
          {isVerified ? (
            <div className="row align-items-center">
              <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
                <div className="text-center">{<img src="/img/login.svg" alt="..." className="img-fluid" />}</div>
              </div>

              <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                <>
                  <div className="text-center">
                    <h1 className="font-merck text-merck-yellow text-center">
                      The Power Tool<b className="font-verdana text-merck-yellow text-center"></b>
                    </h1>
                    <h2 className="font-merck text-merck-teal text-center mt-3">Philippines</h2>
                    {process.env.REACT_APP_ENV === 'dev' && <h3 className="font-merck text-merck-pink text-center mb-5">Development</h3>}
                  </div>
                  <Spinner display={this.state.loading}>
                    <form className="mt-5">
                      <div className="form-group">
                        <label>Email Address</label>
                        <input name="email" type="email" className="form-control" placeholder="john@gmail.com" value={this.state.email} onChange={this.onInputChange} />
                        <span className="text-danger">{this.validator.message('', this.state.email, 'required|email')}</span>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col">
                            <label>Password</label>
                          </div>
                          <div className="col-auto">
                            <Link to="/forgot-password" className="form-text small text-merck-pink" tabIndex="-1">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div className="input-group input-group-merge">
                          <input name="password" type={this.state.showPassword ? 'text' : 'password'} className="form-control form-control-appended" placeholder="Enter your password" value={this.state.password} onChange={this.onInputChange} />
                          <div className="input-group-append">
                            <span className="input-group-text" onClick={this.onShowPassword}>
                              <i className={this.state.showPassword ? 'fe fe-eye' : 'fe fe-eye-off'} />
                            </span>
                          </div>
                        </div>
                        <span className="text-danger">{this.validator.message('', this.state.password, 'required')}</span>
                      </div>
                      <button onClick={this.onSubmit} className="btn btn-lg btn-block btn-merck-yellow" type="submit">
                        Sign In
                      </button>
                      <div className="text-center mt-2">
                        New User?{' '}
                        <Link to="/register" className="text-merck-red">
                          Register
                        </Link>
                      </div>
                    </form>
                  </Spinner>
                </>
              </div>
            </div>
          ) : (
            <div className="row align-items-center h-100">
              <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0">
                <div className="text-center">
                  <img src="/img/not-found.svg" alt="password-reset" className="img-fluid" />
                </div>
              </div>
              <div className="col-12 col-md-5 col-xl-4 order-md-6 my-5">
                <h1 className="font-merck text-merck-yellow mb-3">Sorry! Looks like the domain you tired to access is blocked.</h1>
                <br />
                <h3 className="text-muted mb-3">Please contact your network administrator.</h3>
                <button className="btn btn-block btn-merck-yellow float-left ml-7" style={{ width: '40%' }}>
                  <Link
                    to="#"
                    onClick={(e) => {
                      window.location = 'mailto:merck-ph@neetable.com';
                      e.preventDefault();
                    }}
                  >
                    Contact Admin
                  </Link>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(withRouter(AppLogin));
