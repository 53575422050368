import React from 'react';
import { withRouter } from 'react-router-dom';

class Header extends React.Component {
  render() {
    const { location, title } = this.props;
    const pathname = location.pathname;
    return (
      <>
        <div className="header">
          <div className={pathname === '/app/products' || pathname === '/app/quote-request' ? 'container-fluid' : 'container container-fluid'}>
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  <h1 className="header-title">{title}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Header);
