import 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Spinner from '../../components/Spinner';
import Header from '../../admin/components/Header';
import SimpleReactValidator from 'simple-react-validator';
import { TAX_PATTERN } from '../../admin/constants/Constants';
import { getDistributors, getUserById, updateUserById, updateDistributorById } from '../../services';

class AdminUserView extends React.Component {
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
    this.state = {
      company: '',
      value: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phone1: '',
      phone2: '',
      name: '',
      email: '',
      imageUrl: '',
      loading: false,
      designation: '',
      distributors: [],
      distributorId: '',
      selectedOption: [],
      companyClass: 'is-invalid',
      taxIdClass: 'is-invalid',
      addressClass: 'is-invalid',
      cityClass: 'is-invalid',
      stateClass: 'is-invalid',
      codeClass: 'is-invalid',
      nameClass: 'is-invalid',
      isButtonEnabled: false,
    };
  }

  async componentDidMount() {
    await this.getUserData();
    await this.getDistributors();
  }

  getUserData = async () => {
    this.setState({ loading: true });
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    await this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          const response = await getUserById(id);
          if (response) {
            if (response.status === 200) {
              let user = response.data;
              this.setState({
                company: user.company,
                value: user.tax_id,
                address: user.address.address1,
                distributorId: user.distributor_id,
                city: user.address.city,
                state: user.address.state,
                zipCode: user.address.postal_code,
                phone1: user.phone1,
                name: user.name,
                designation: user.designation,
                email: user.email,
                id: user.id,
                phone2: user.phone2,
                imageUrl: user.photo_url,
                loading: false,
                companyClass: user.company && user.company.length <= 0 ? 'is-invalid' : 'is-valid',
                nameClass: user.name.length <= 0 ? 'is-invalid' : 'is-valid',
                taxIdClass: user.tax_id.length <= 0 ? 'is-invalid' : 'is-valid',
                addressClass: user.address.address1.length <= 0 ? 'is-invalid' : 'is-valid',
                cityClass: user.address.city.length <= 0 ? 'is-invalid' : 'is-valid',
                stateClass: user.address.state.length <= 0 ? 'is-invalid' : 'is-valid',
                codeClass: user.address.postal_code.length <= 0 ? 'is-invalid' : 'is-valid',
              });
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/admin');
              }
              this.setState({ loading: false });
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
          this.setState({ loading: false });
        }
      }
    );
  };

  getDistributors = async () => {
    const { dispatch, history } = this.props;
    this.setState({ loading: true });
    try {
      const token = localStorage.getItem('token');
      const response = await getDistributors(token);
      if (response) {
        if (response.status === 200) {
          const distributorsArray = response.data;
          this.setState({
            distributors: distributorsArray,
            loading: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  onCodeChange = ({ target: { name, value } }) => {
    if (!isNaN(Number(value))) {
      this.setState({ [name]: value }, () => {
        this.setState({
          codeClass: value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
      });
    }
  };

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    switch (event.target.name) {
      case 'company':
        this.setState({
          companyClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
        break;

      case 'address':
        this.setState({
          addressClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
        break;

      case 'city':
        this.setState({
          cityClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
        break;

      case 'name':
        this.setState({
          nameClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
        break;
      case 'state':
        this.setState({
          stateClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid',
        });
        break;

      default:
        break;
    }
  };

  onTinChange = (event) => {
    let taxIdPattern = TAX_PATTERN;
    this.setState({
      value: event.target.value,
      taxIdClass: taxIdPattern.test(event.target.value) ? 'is-valid' : 'is-invalid',
    });
  };

  beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }
    return {
      value,
      selection,
    };
  };

  updateDistributor = async () => {
    const { address, city, state, zipCode, distributorId } = this.state;
    const distributorData = {
      address: { address1: address, city: city, state: state, postal_code: zipCode },
    };
    const response = await updateDistributorById(distributorId, distributorData);
    try {
      if (response) {
        if (response.status === 200 || response.status === 200) {
          await toast.success('Your account has been edited successfully.');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            const sessionExpired = 'Sorry your session has expired you need to Re-Login to access the Application.';
            toast.error(sessionExpired);
            await this.props.dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            window.location.href = '/admin';
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  onSubmit = async (event) => {
    event.preventDefault();
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    this.setState({ loading: true }, async () => {
      let { address, city, company, name, designation, distributorId, zipCode, value, phone1, phone2, state } = this.state;
      if (this.state.companyClass === 'is-valid' && this.state.addressClass === 'is-valid' && this.state.cityClass === 'is-valid' && this.state.nameClass === 'is-valid' && this.state.codeClass === 'is-valid' && this.state.taxIdClass === 'is-valid' && this.state.stateClass === 'is-valid') {
        let userData = {
          address: {
            address1: address,
            city: city,
            state: state,
            postal_code: zipCode,
          },
          company: company,
          name: name,
          designation: designation,
          phone1: phone1,
          phone2: phone2,
          tax_id: value,
          distributor_id: distributorId,
        };
        const response = await updateUserById(id, userData);
        try {
          if (response) {
            if (response.status === 200) {
              await this.updateDistributor();
              await this.getUserData();
              await history.push('/admin/users');
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/admin');
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
      } else {
        this.validator.showMessages();
        toast.error('Please fill all the Mandatory details');
        this.forceUpdate();
        event.preventDefault();
        this.setState({ loading: false });
      }
    });
  };

  onCompanyChange = (selectedOption) => {
    this.setState({ selectedOption, isButtonEnabled: true });
  };

  overRideCustomerData = () => {
    const { selectedOption, distributors } = this.state;
    const mappedDistributorData = distributors.find((x) => x.id === selectedOption.value);
    if (mappedDistributorData) {
      const distributorAddress = mappedDistributorData.address;
      this.setState({
        company: selectedOption.label,
        distributorId: mappedDistributorData.id,
        address: distributorAddress.address1,
        city: distributorAddress.city,
        state: distributorAddress.state,
        zipCode: distributorAddress.postal_code,
        addressClass: distributorAddress.address1.length <= 0 ? 'is-invalid' : 'is-valid',
        cityClass: distributorAddress.city.length <= 0 ? 'is-invalid' : 'is-valid',
        stateClass: distributorAddress.state.length <= 0 ? 'is-invalid' : 'is-valid',
        codeClass: distributorAddress.postal_code.length <= 0 ? 'is-invalid' : 'is-valid',
      });
    }
  };

  render(props) {
    const isViewRole = this.props.user.specialRole;
    const { loading, imageUrl, selectedOption, isButtonEnabled, company, value, address, city, state, zipCode, phone1, phone2, name, designation, email, companyClass, taxIdClass, cityClass, stateClass, codeClass, nameClass, addressClass } = this.state;
    const options = this.state.distributors.map((e) => ({
      value: e.id,
      label: e.name,
    }));
    return (
      <>
        <div className="container mt-5">
          <Spinner display={loading}>
            <Header section="Overview" title={name}>
              <Link to="/admin/users" className="btn btn-outline-secondary">
                Cancel
              </Link>
              {!isViewRole && (
                <button type="button" className="btn btn-merck-pink ml-2" onClick={this.onSubmit}>
                  Save
                </button>
              )}
            </Header>

            <div className="row">
              <div className="col-12 col-md-8">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label>Company Name</label> <span className="text-danger">*</span>
                            <input disabled type="text" className={`form-control ${companyClass}`} id="company" name="company" placeholder="e.g. MERCK, INC." onChange={this.onInputChange} value={company} style={{ backgroundColor: '#F1F2F3' }} />
                            <span className="text-danger">{this.validator.message('company', company, 'required')}</span>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-outline-info" data-toggle="modal" data-target="#distributorMappingModal">
                              Link Distributors
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="taxId" className="mb-1">
                              TIN
                            </label>
                            <span className="text-danger">*</span>
                            <InputMask mask="999-999-999-999" className={`form-control ${taxIdClass}`} maskChar={null} value={this.state.value} placeholder="e.g XXX-XXX-XXX-XXX" onChange={this.onTinChange} beforeMaskedValueChange={this.beforeMaskedValueChange} />
                            <span className="text-danger">{this.validator.message('tin', value, ['required', { regex: TAX_PATTERN }])}</span>
                          </div>
                          <div className="form-group">
                            <label>Registered Addresss</label>
                            <span className="text-danger">*</span>
                            <input type="text" className={`form-control ${addressClass}`} id="registeredAddress" name="address" placeholder="e.g. 36/F The Finance Center | 26th Street corner 9th Avenue Bonifacio Global City | 1634 Taguig City" onChange={this.onInputChange} value={address} />
                            <span className="text-danger">{this.validator.message('address', address, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>City</label>
                            <span className="text-danger">*</span>
                            <input type="text" name="city" className={`form-control ${cityClass}`} placeholder="e.g. Taguig" value={city} onChange={this.onInputChange} />
                            <span className="text-danger">{this.validator.message('city', city, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Province</label>
                            <span className="text-danger">*</span>
                            <input type="text" name="state" className={`form-control ${stateClass}`} placeholder="e.g. Manila" value={state} onChange={this.onInputChange} />
                            <span className="text-danger">{this.validator.message('state', state, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Postal Code</label> <span className="text-danger">*</span>
                            <input type="text" maxLength="4" className={`form-control ${codeClass}`} id="zipCode" name="zipCode" placeholder="e.g. 1001" onChange={this.onCodeChange} value={zipCode} />
                            <span className="text-danger">{this.validator.message('Postal Code', zipCode, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Contact Number</label>
                            <input type="tel" className="form-control" id="phoneNumber" name="phone1" onChange={this.onInputChange} value={phone1} />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Contact Person</label> <span className="text-danger">*</span>
                            <input type="text" className={`form-control ${nameClass}`} id="name" name="name" placeholder="e.g. John" onChange={this.onInputChange} value={name} />
                            <span className="text-danger">{this.validator.message('Contact Person', name, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Mobile Number</label>
                            <input type="tel" className="form-control" id="mobileNumber" name="phone2" onChange={this.onInputChange} value={phone2} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Title / Designation</label>
                            <input type="text" className="form-control" id="designation" name="designation" placeholder="e.g. Sales Manager" onChange={this.onInputChange} value={designation} />
                            <span className="text-danger">{this.validator.message('Designation', designation, 'required')}</span>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label>Email</label> <span className="text-danger">*</span>
                            <input disabled type="email" className="form-control" id="inputEmail1" name="email" placeholder="e.g. john@merckgroup.com" value={email} style={{ backgroundColor: '#F1F2F3' }} />
                          </div>
                        </div>
                      </div>
                      <div className="modal fade" id="distributorMappingModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h3 className="modal-title" id="exampleModalLongTitle">
                                Mapping Distributors
                              </h3>
                              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <div className="form-group">
                                <label htmlFor="Inputtag">Company Name</label> <span className="text-danger">*</span>
                                <Select value={selectedOption || []} onChange={this.onCompanyChange} options={options} required />
                                <br />
                                <span className="text-danger">Note: Selecting a distributor/Company name will override the company information submitted by customer</span>
                              </div>
                            </div>
                            <div className="modal-footer" data-dismiss="modal">
                              <button type="button" disabled={!isButtonEnabled} className="btn btn-merck-pink" onClick={this.overRideCustomerData}>
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card">
                  <div className="card-body text-center">
                    <div className="avatar avatar-xxl mb-2">
                      <img src={imageUrl && imageUrl.length > 0 ? imageUrl : '/img/default-user.png'} alt="user" className="avatar-img rounded-circle border border-4 border-card" />
                    </div>
                    <h2 className="card-title text-capitalize">{name.length > 0 && name.length > 0 ? name : ''}</h2>
                    <p className="small text-muted mb-3">{designation && designation.length > 0 ? designation : ' '}</p>
                  </div>
                </div>
              </div>
            </div>
          </Spinner>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminUserView);
