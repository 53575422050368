import React, { Component } from 'react';

class ContentContainer extends Component {
  render() {
    const { size, isKeywordAndNoDataFound, children } = this.props;
    return (
      <>
        <div className={size === 0 ? 'm-5 text-center' : 'd-none'}>
          <div className="row">
            <div className="col-lg-4 offset-lg-4 text-center">
              <img src="/img/empty.svg" className="img-fluid" alt="no-data-found" />
              {isKeywordAndNoDataFound ? <p>Oops! No results found.</p> : <p>Oops! We don't have any data at this time</p>}
            </div>
          </div>
        </div>
        <div className={size === 0 ? 'd-none' : ''}>{children}</div>
      </>
    );
  }
}

export default ContentContainer;
