import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Header from '../components/AdminHeader';
import Spinner from '../../components/Spinner';
import { createDocument, uploadFile } from '../../services';
import { toast } from 'react-toastify';

const formData = {
    name: "",
    doc: "",
}

const schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    doc: Yup.mixed().required('Required'),

})


const AdminUploadDocument = (props) => {
    const dispatch = useDispatch();
    const [spinner, setSpinner] = useState(false);

    const doLogout = async () => {
        dispatch({ type: 'USER', payload: {} });
        localStorage.removeItem('token');
        window.location.href = '/admin';
    };

    const handleUpload = async (values) => {
        setSpinner(true)
        const type = values?.doc?.type === "application/pdf" ? "pdf" : "doc"
        const fileName = `${values?.name?.replaceAll(" ", "_")}.${type === "pdf" ? "pdf" : "docx"}`
        try {
            const fileResponse = await uploadFile(values?.doc, fileName);

            if (fileResponse && (fileResponse.status === 401 || fileResponse.data === 'Unauthorized')) {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                doLogout()
            }
            if (fileResponse.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
                setSpinner(false)
                return
            }

            const url = fileResponse?.data[0]

            const document = {
                name: values?.name,
                file_path: url,
                type
            }

            const response = await createDocument(document)
            if (response && (response.status === 200 || response.status === 201)) {
                toast.success(`Document has been created`);
                await props.history.push('/admin/documents');
            } else if (response?.response?.status === 401 || response?.response?.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                doLogout()
            } else if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
            }
        } catch (error) {
            console.warn(error);
        } finally {
            setSpinner(false)
        }
    }


    return (
        <>
            <Header title="Upload Documents">
                <div className="col-auto">
                    <Link to="/admin/documents" className="btn btn-outline-secondary">
                        Close
                    </Link>
                </div>
            </Header>
            <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                        <Formik initialValues={formData} enableReinitialize validationSchema={schema} onSubmit={handleUpload}>
                            {({ handleChange, handleSubmit, values, errors, setFieldValue }) => {
                                return (
                                    <form className='d-flex flex-column' onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label className="form-label">Document Name <span className="text-danger">*</span></label>
                                            <input type="text" id="name" className="form-control" value={values?.name} onChange={handleChange} />
                                            <span className='text-danger'>{errors?.name}</span>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="doc" className="form-label">Select Document <span className="text-danger">*</span></label>
                                            <div className="input-group mb-3">
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="doc" accept=".doc,.docx,.pdf" onChange={(e) => { setFieldValue("doc", e?.target?.files[0]) }} />
                                                    <label className="custom-file-label" for="inputGroupFile01">  {values.doc ? values.doc.name : 'Choose file'}</label>
                                                </div>
                                                <span className='text-danger'>{errors?.doc}</span>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end mt-3'>
                                            <Spinner display={spinner}>
                                                <button type='submit' className='form-group btn btn-primary'>Upload Document</button>
                                            </Spinner>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>

                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};


export default connect(mapStateToProps)(AdminUploadDocument);
