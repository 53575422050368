import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const auth = async (credentials) => {
  try {
    const URL = BASE_URL.concat('/auth');
    const data = await axios.post(URL, credentials);
    return data;
  } catch (err) {
    return err;
  }
};

const verifyApi = async () => {
  try {
    const URL = BASE_URL.concat('/health/env');
    const data = await axios.get(URL);
    return data;
  } catch (err) {
    return err;
  }
};

const resetPassword = async (email) => {
  try {
    const URL = BASE_URL.concat('/auth/reset-password');
    const data = await axios.post(URL, email);
    return data;
  } catch (err) {
    return err;
  }
};

const registerUser = async (user) => {
  try {
    const URL = BASE_URL.concat('/auth/register');
    const data = await axios.post(URL, user);
    return data;
  } catch (err) {
    return err;
  }
};

export { auth, resetPassword, registerUser, verifyApi };
