import React from 'react';
import { map } from 'lodash';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import Header from '../../components/Header';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import { Bar, Doughnut } from 'react-chartjs-2';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import DashboardCard from '../components/DashboardCard';
import { trackPageView, trackEvent } from '../../helpers/analytics';
import { getAllQuotes, getDistributors, getAllBussinessUnit } from '../../services';

const moment = require('moment');
const MomentRange = require('moment-range');
const momentR = MomentRange.extendMoment(moment);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(30, 'days').endOf('day')['_d'],
      endDate: moment().endOf('day')['_d'],
      chartData: {},
      quoteList: [],
      quoteCount: 0,
      loading: false,
      productList: [],
      doughnutData: {},
      groupOptions: [],
      allCustomers: [],
      selectedGroup: [],
      selectDates: false,
      quoteTotalRevenue: 0,
      topDistributorsRevenue: 0,
      topDistributorRevenue: 0,
    };
  }

  async componentDidMount() {
    trackPageView();
    this.setInitailData();
  }

  setInitailData = () => {
    this.setState(
      {
        startDate: moment().subtract(30, 'days').endOf('day')['_d'],
        endDate: moment().endOf('day')['_d'],
        loading: true,
      },
      async () => {
        await this.getGroupCodes();
        await this.getDashboardData();
      }
    );
  };

  componentDidUpdate(prevprops, prevState) {
    const { selectedGroup } = this.state;
    if (prevState.selectedGroup !== selectedGroup) {
      this.getAllCustomers(this.state.quoteList);
    }
  }

  doLogout = async () => {
    await this.props.dispatch({ type: 'USER', payload: {} });
    await localStorage.removeItem('token');
    window.location.href = '/admin';
  };

  getGroupCodes = async () => {
    const { selectedGroup } = this.state;
    try {
      const response = await getAllBussinessUnit();
      if (response) {
        if (response && response.status === 200) {
          const groupsArray = response.data;
          this.setState({ groupsArray });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }

    const options = map(this.state.groupsArray, (e) => ({
      value: e.name,
      label: e.name,
    }));

    const defaultOption = { value: 'ALL', label: 'ALL' };
    const isOptions = !!(Array.isArray(selectedGroup) && selectedGroup.length);
    if (!isOptions) {
      this.setState({
        selectedGroup: defaultOption,
      });
    }
    this.setState({ groupOptions: options });
  };

  onGroupChange = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  getDashboardData = async () => {
    //Get Quotes in the given date range
    const allQuotes = await this.getAllQuotes();
    const quoteTotalRevenue = await (allQuotes && allQuotes.reduce((sum, item) => sum + item.total, 0));

    //Total number of orders received
    const quoteCount = await (allQuotes && allQuotes.length);

    //Total Revenue received from top distributor
    const topDistributors = await this.getTopDistributors(allQuotes);
    const topDistributorRevenue = ((await topDistributors) && topDistributors.length) > 0 ? topDistributors[0].totalRevenue : 0;

    //Total revenue from top distributor orders
    const topDistributorsRevenue = await this.getTopDistributorsRevenue(topDistributors);

    //Bar graph data view - Date v/s quote count
    const orders = await this.getChartData(allQuotes);

    //Top Customers ranking for table view
    const allCustomers = await this.getAllCustomers(allQuotes);

    //Top Products data for Pie chart view
    const topProducts = await this.getTopProducts(allQuotes);

    //Doughnut data - Top 5 Products
    const doughnutData = await this.getDoughnutData(topProducts);

    await this.setState(
      {
        quoteTotalRevenue,
        quoteCount,
        topDistributors,
        topDistributorRevenue,
        topDistributorsRevenue,
        chartData: orders.chartData,
        dailyOrders: orders.dailyOrders,
        topProducts,
        doughnutData,
        allCustomers,
      },
      () => {
        this.setState({ loading: false });
      }
    );
  };

  getAllQuotes = async () => {
    let { startDate, endDate } = this.state;
    try {
      const response = await getAllQuotes();
      startDate = moment(startDate).format('llll');
      endDate = moment(endDate).format('llll');
      if (response) {
        if (response.status === 200) {
          const allQuotes = response.data.filter((item) => moment(moment(item.created_on).format('llll')).isBetween(startDate, endDate));
          this.setState({
            quoteList: allQuotes,
          });
          return allQuotes;
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  getTopDistributors = async (allQuotes) => {
    let group = await allQuotes.reduce((accumulator, quote) => {
      accumulator[quote.distributor.name] = [...(accumulator[quote.distributor.name] || []), quote];
      return accumulator;
    }, {});

    let topDistributors = await Object.entries(group)
      .map(([key, value]) => ({ [key]: value }))
      .map((distributor) => {
        for (let x in distributor) {
          return {
            distributorName: x,
            distributorId: distributor[x][0].distributor.id,
            totalRevenue: distributor[x].reduce((revenue, quote) => revenue + Number(quote.total), 0),
          };
        }
        return null;
      })
      .sort((a, b) => (b.totalRevenue > a.totalRevenue ? 1 : b.totalRevenue < a.totalRevenue ? -1 : 0))
      .filter((_element, index) => index < 3);
    return topDistributors;
  };

  getTopDistributorsRevenue = async (topDistributors) => {
    return await topDistributors.reduce((topDistributorsRevenue, topDistributor) => topDistributorsRevenue + topDistributor.totalRevenue, 0);
  };

  getChartData = async (allQuotes) => {
    let range = momentR.range(this.state.startDate, this.state.endDate);

    let lastSevenDays = Array.from(range.by('day'));
    lastSevenDays = lastSevenDays.map((ele) => ele.format('ll'));

    let group = await allQuotes.reduce((accumulator, quote) => {
      accumulator[moment(quote.created_on).format('ll')] = [...(accumulator[moment(quote.created_on).format('ll')] || []), quote];
      return accumulator;
    }, {});

    let dailyOrders = await Object.entries(group)
      .map(([key, value]) => ({ [key]: value }))
      .map((quote) => {
        for (let x in quote) {
          if (lastSevenDays.includes(x)) {
            lastSevenDays[lastSevenDays.indexOf(x)] = {
              date: x,
              orders: quote[x].length,
            };
          }
          return {
            date: x,
            orders: quote[x].length,
          };
        }
        return null;
      });

    dailyOrders = await lastSevenDays.map((ele) => {
      if (typeof ele === 'string') return { date: ele, orders: 0 };
      else return ele;
    });

    let chartData = await {
      labels: dailyOrders.map((quote) => quote.date),
      datasets: [
        {
          data: dailyOrders.map((quote) => quote.orders),
          backgroundColor: '#2c7be5',
          hoverBackgroundColor: '#2c7be5',
        },
      ],
    };
    return { chartData, dailyOrders };
  };

  getAllCustomers = async (allQuotes) => {
    const { selectedGroup } = this.state;
    try {
      const token = localStorage.getItem('token');
      const response = await getDistributors(token);
      if (response) {
        if (response.status === 200) {
          const dbDistributors = response.data;
          this.setState({ dbDistributors });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
    map(allQuotes, (quote) => {
      map(quote.products, (item) => {
        const subTotal = item.sub_total;
        // const freight = quote && quote.request_type === 'Delivery' ? (0.5 / 100) * subTotal : quote && quote.request_type === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
        // const totalForTax = freight + subTotal;
        // const tax = (12 / 100) * totalForTax;
        // const total = subTotal + freight + tax;
        const totalForTax = 0 + subTotal;
        const tax = (12 / 100) * totalForTax;
        const total = subTotal + 0 + tax;
        item.distributorName = quote.distributor.name;
        item.total = total;
      });
    });
    let group = await allQuotes.reduce((accumulator, quote) => {
      accumulator[quote.customer.company] = [...(accumulator[quote.customer.company] || []), quote];
      return accumulator;
    }, {});

    let allCustomers = await Object.entries(group)
      .map(([key, value]) => ({ [key]: value }))
      .map((customer) => {
        for (let x in customer) {
          const listData = [];
          if (selectedGroup && selectedGroup.label !== 'ALL') {
            const data = allQuotes.map((item) => {
              item.products.filter((product) => {
                const BussinessUnit = selectedGroup && selectedGroup.label;
                if (product.distributorName.toLowerCase() === x.toLowerCase() && product.business_unit.toLowerCase() === BussinessUnit.toLowerCase()) {
                  listData.push(product);
                }
                return listData;
              });
              return data;
            });
            return {
              customerName: x,
              totalRevenue: listData.reduce((revenue, quote) => revenue + Number(quote.total), 0),
              quoteCount: customer[x].length,
            };
          } else {
            return {
              customerName: x,
              totalRevenue: customer[x].reduce((revenue, quote) => revenue + Number(quote.total), 0),
              quoteCount: customer[x].length,
            };
          }
        }
        return null;
      })
      .sort((a, b) => (b.totalRevenue > a.totalRevenue ? 1 : b.totalRevenue < a.totalRevenue ? -1 : 0));

    map(this.state.dbDistributors, (distributor) => {
      const data = allCustomers.filter((item) => item.customerName === distributor.name);
      if (data.length === 0)
        allCustomers.push({
          customerName: distributor.name,
          totalRevenue: 0,
          quoteCount: 0,
        });
      return null;
    });
    this.setState({ allCustomers });
    return allCustomers;
  };

  getTopProducts = async (allQuotes) => {
    let allProducts = [];
    allQuotes.forEach((quote) => {
      quote.products.forEach((product) => {
        allProducts = [...allProducts, product];
      });
    });
    this.setState({ productList: allProducts });
    let group = await allProducts.reduce((accumulator, product) => {
      accumulator[product.name] = [...(accumulator[product.name] || []), product];
      return accumulator;
    }, {});

    let topProducts = await Object.entries(group)
      .map(([key, value]) => ({ [key]: value }))
      .map((product) => {
        for (let x in product) {
          return {
            productId: product[x][0].id,
            productName: x,
            count: product[x].reduce((totalQuantity, item) => totalQuantity + item.quantity, 0),
          };
        }

        return null;
      })
      .sort((a, b) => (b.count > a.count ? 1 : b.count < a.count ? -1 : 0))
      .filter((_element, index) => index < 20);

    return topProducts;
  };

  getDoughnutData = async (topProducts) => {
    let ProductsName = await topProducts.map((product) => product.productName);
    let ProductsCount = await topProducts.map((product) => product.count);

    let doughnutData = await {
      labels: ProductsName,
      datasets: [
        {
          label: 'Products',
          backgroundColor: ['#2C7BE5', '#5997eb', '#95bdf2', '#dbe8fb', '#ecf3fd'],
          hoverBackgroundColor: ['#2C7BE5', '#5997eb', '#95bdf2', '#dbe8fb', '#ecf3fd'],
          data: ProductsCount,
        },
      ],
    };
    return doughnutData;
  };

  handleDateSelect = (ranges) => {
    this.setState({
      startDate: moment(ranges.selection.startDate).startOf('day')._d,
      endDate: moment(ranges.selection.endDate).endOf('day')._d,
    });
  };

  handleCancel = () => {
    this.setState({
      selectDates: false,
    });
  };

  handleApply = () => {
    this.setState(
      {
        loading: true,
        selectDates: false,
      },
      async () => {
        this.getDashboardData();
      }
    );
  };

  selectDates = () => {
    trackEvent('Admin Dashboard', 'Select Dates');
    this.setState({
      selectDates: true,
    });
  };

  downloadDashboardData = (topDistributors, dailyOrders, topProducts, allCustomers) => {
    trackEvent('Admin Dashboard', 'Download Dashboard Data');

    let dateRange = `${moment(this.state.startDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`;
    let saveAs = require('file-saver');
    let xl = require('excel4node');
    let sheets = ['Top Distributors', 'Orders', 'Top Products', 'Top Customers'];
    let wb = new xl.Workbook();

    let tableHeaderStyle = wb.createStyle({ font: { size: 11, bold: true }, fill: { type: 'pattern', patternType: 'solid', fgColor: '#edf2f9' } });
    let tableItemStyle = wb.createStyle({ font: { size: 11 } });
    let moneyItemStyle = wb.createStyle({ font: { size: 11 }, numberFormat: '₱#,##0.00; (₱#,##0.00); -' });

    //Add data in sheets
    for (let i = 0; i < sheets.length; i++) {
      const sheet = sheets[i];
      switch (sheet) {
        case 'Top Distributors':
          {
            let ws = wb.addWorksheet(sheet);
            ws.column(1).setWidth(8);
            ws.column(2).setWidth(15);
            ws.column(3).setWidth(35);
            ws.column(4).setWidth(15);

            //Headers
            ws.cell(1, 1).string('Rank').style(tableHeaderStyle);
            ws.cell(1, 2).string('Distributor ID').style(tableHeaderStyle);
            ws.cell(1, 3).string('Distributor Name').style(tableHeaderStyle);
            ws.cell(1, 4).string('Total Revenue').style(tableHeaderStyle);

            //Data
            for (let j = 0; j < topDistributors.length; j++) {
              let distributor = topDistributors[j];
              let row = 2 + j;
              ws.cell(row, 1)
                .number(j + 1)
                .style(tableItemStyle);
              ws.cell(row, 2).string(distributor.distributorId).style(tableItemStyle);
              ws.cell(row, 3).string(distributor.distributorName).style(tableItemStyle);
              ws.cell(row, 4).number(distributor.totalRevenue).style(moneyItemStyle);
            }
          }
          break;

        case 'Orders':
          {
            let ws = wb.addWorksheet(sheet);
            ws.column(1).setWidth(12);
            ws.column(2).setWidth(20);

            //Headers
            ws.cell(1, 1).string('Date').style(tableHeaderStyle);
            ws.cell(1, 2).string('Total Orders Received').style(tableHeaderStyle);

            //Data
            for (let j = 0; j < dailyOrders.length; j++) {
              let orders = dailyOrders[j];
              let row = 2 + j;

              ws.cell(row, 1).string(orders.date).style(tableItemStyle);
              ws.cell(row, 2).number(orders.orders).style(tableItemStyle);
            }
          }
          break;

        case 'Top Products':
          {
            let ws = wb.addWorksheet(sheet);
            ws.column(1).setWidth(8);
            ws.column(2).setWidth(15);
            ws.column(3).setWidth(40);
            ws.column(4).setWidth(15);

            //Headers
            ws.cell(1, 1).string('Rank').style(tableHeaderStyle);
            ws.cell(1, 2).string('Catalog Number').style(tableHeaderStyle);
            ws.cell(1, 3).string('Product').style(tableHeaderStyle);
            ws.cell(1, 4).string('Orders Count').style(tableHeaderStyle);

            //Data
            for (let j = 0; j < topProducts.length; j++) {
              let product = topProducts[j];
              let row = 2 + j;

              ws.cell(row, 1)
                .number(j + 1)
                .style(tableItemStyle);
              ws.cell(row, 2).string(product.productId).style(tableItemStyle);
              ws.cell(row, 3).string(product.productName).style(tableItemStyle);
              ws.cell(row, 4).number(product.count).style(tableItemStyle);
            }
          }
          break;

        case 'Top Customers':
          {
            let ws = wb.addWorksheet(sheet);
            ws.column(1).setWidth(10);
            ws.column(2).setWidth(15);
            ws.column(3).setWidth(35);
            ws.column(4).setWidth(20);
            ws.column(5).setWidth(20);

            //Headers
            ws.cell(1, 1).string('Rank').style(tableHeaderStyle);
            ws.cell(1, 2).string('Company Name').style(tableHeaderStyle);
            ws.cell(1, 3).string('Total Orders Received').style(tableHeaderStyle);
            ws.cell(1, 4).string('Total Revenue Received').style(tableHeaderStyle);

            //Data
            for (let j = 0; j < allCustomers.length; j++) {
              let customer = allCustomers[j];
              let row = 2 + j;

              ws.cell(row, 1)
                .number(j + 1)
                .style(tableItemStyle);
              ws.cell(row, 2).string(customer.customerName).style(tableItemStyle);
              ws.cell(row, 3).number(customer.quoteCount).style(tableItemStyle);
              ws.cell(row, 4).number(customer.totalRevenue).style(moneyItemStyle);
            }
          }
          break;

        default:
          break;
      }
    }
    wb.writeToBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `reports_${dateRange}.xlsx`);
    });
  };

  render() {
    const { topDistributors, dailyOrders, allCustomers, topProducts, selectedGroup, groupOptions } = this.state;
    const selectionRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: 'selection',
    };
    const selectedBussinessUnit = selectedGroup && selectedGroup.label;

    let totalBusinessRevenue = allCustomers && allCustomers.reduce((revenue, quote) => revenue + Number(quote.totalRevenue), 0);
    totalBusinessRevenue = Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(totalBusinessRevenue);

    return (
      <div>
        <Header title={'Admin Dashboard'} hasButton={this.state.selectDates} />
        <Spinner display={this.state.loading}>
          <div className="container">
            <div className="row">
              <div className="col">
                <button className="btn btn-white mb-3" onClick={this.selectDates}>{`${moment(this.state.startDate).format('ll')} - ${moment(this.state.endDate).format('ll')}`}</button>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary mb-3"
                  type="button"
                  onClick={() => {
                    this.downloadDashboardData(topDistributors, dailyOrders, topProducts, allCustomers);
                  }}
                >
                  <i className="fe fe-download mr-1" /> Download
                </button>
              </div>
            </div>
          </div>

          {this.state.selectDates && (
            <div className="container" style={{ position: 'absolute', zIndex: 9 }}>
              <DateRangePicker ranges={[selectionRange]} onChange={this.handleDateSelect} showDateDisplay={true} hasCustomRendering={true} inputRanges={[]} />
              <div className="d-flex" style={{ position: 'absolute', bottom: '3%', left: '5%' }}>
                <button className="btn btn-sm btn-primary m-2" onClick={this.handleApply}>
                  Apply
                </button>
                <button className="btn btn-sm btn-light m-2" onClick={this.handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          )}

          <div className="container" style={{ position: 'relative' }}>
            <div className="row mb-3">
              <DashboardCard title="Total Revenue" value={this.state.quoteTotalRevenue} isAmount={true} />
              <DashboardCard title="Quotes Count" value={this.state.quoteCount} isAmount={false} />
              <DashboardCard title="Top 3 Distributors Revenue" value={this.state.topDistributorsRevenue} isAmount={true} />
              <DashboardCard title="Top Distributor Revenue" value={this.state.topDistributorRevenue} isAmount={true} />
            </div>

            <div className="row">
              {/*Date v/s Quotes count on Bar graph*/}
              <div className="col-12 col-xl-8">
                <div className="card h-100">
                  <div className="card-header">
                    <h4>Quotes</h4>
                  </div>
                  <div className="card-body">
                    <div className="px-4">
                      <Bar
                        data={this.state.chartData}
                        options={{
                          legend: {
                            display: false,
                          },
                          scales: {
                            xAxes: [
                              {
                                fontColor: '#95aac9',
                                barThickness: 10,
                                gridLines: {
                                  offsetGridLines: true,
                                  color: '#3a4554',
                                  lineWidth: 0.1,
                                },
                                ticks: {
                                  fontColor: '#95aac9',
                                  padding: 10,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                cornerRadius: 100,
                                barThickness: 10,
                                ticks: {
                                  beginAtZero: true,
                                  fontColor: '#95aac9',
                                  userCallback: function (label, _index, _labels) {
                                    if (Math.floor(label) === label) {
                                      return label;
                                    }
                                  },
                                },
                              },
                            ],
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Top Products on Pie chart */}
              <div className="col-12 col-xl-4">
                <div className="card h-100">
                  <div className="card-header">
                    <h4 className="card-header-title">Top Products</h4>
                  </div>
                  <div className="card-body">
                    <Doughnut
                      width={250}
                      height={250}
                      data={this.state.doughnutData}
                      options={{
                        legend: {
                          display: false,
                          position: 'bottom',
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4 mb-4">
              <div className="col">
                <div className="row">
                  <label className="font-weight-bold col-auto mt-2">Business Unit</label>
                  <Select className="col-5" value={selectedGroup || []} onChange={this.onGroupChange} options={groupOptions} placeholder="Business Unit" />
                </div>
              </div>
              <div className="col-auto">
                {selectedBussinessUnit !== 'ALL' && (
                  <div className="ml-2">
                    <button className="btn btn-white mb-2">Total Revenue: {totalBusinessRevenue}</button>
                  </div>
                )}
              </div>
            </div>

            {/* Top Customers ranking in table */}
            <div className="row mt-1">
              <div className="col-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-sm table-nowrap card-table">
                      <thead>
                        <tr>
                          <th>Rank</th>
                          <th>Distributor/Company Name</th>
                          <th className="text-center">Business Unit</th>
                          <th className="text-center">Quotes Count</th>
                          <th className="text-center">Total Revenue</th>
                        </tr>
                      </thead>
                      <tbody className="list">
                        {this.state.allCustomers &&
                          map(this.state.allCustomers, (customer, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{customer.customerName}</td>
                              <td className="text-center">{selectedBussinessUnit}</td>
                              <td className="text-center">{customer.quoteCount}</td>
                              <td className="text-center">
                                <Amount value={customer.totalRevenue} />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spinner>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Dashboard);
