import React, { Component } from 'react';
import AdminNavigation from './AdminNavigation';

class AdminLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <AdminNavigation />
        <div className="main-content">{children}</div>
      </>
    );
  }
}

export default AdminLayout;
