import React from 'react';
import {  withRouter } from 'react-router-dom';

const linkStyle = {
  color: '#3870e4',
  padding: '6px 8px',
  fontSize: '1rem',
  lineHeight: '1.75',
  fontWeight: 'bold',
  backgroundColor: '#f6c343',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  textAlign: 'right',
  position: "absolute",
  top:"30%",
  left: "85%",
  transform: "translate(-50%, -70%)"
};

class PageNotFound extends React.Component {
    constructor(props) {
    super(props);
    this.state = {};
  }

  redirectToLogin = () => {
    const { location,history } = this.props;
    const isAdmin = location.pathname.indexOf("admin");
    isAdmin === 1 ? history.push('/admin') : history.push('/');
  };
  
  render() {
    return <div className='d-flex align-items-center vh-100 bg-merck-purple text-white'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 offset-md-2'>
            <img src='/img/not-found.svg' alt='404' className='image-fluid' />
          <button
              type="submit"
              style={linkStyle}
              onClick={this.redirectToLogin}
            >
              GO BACK TO HOMEPAGE
            </button>
          </div>
        </div>
      </div>
    </div>;
  }
}

export default (withRouter(PageNotFound));