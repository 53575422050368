import 'firebase/auth';
import React from 'react';
import Swal from 'sweetalert';
import { filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Header from '../../admin/components/Header';

class AppCartEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      updatedProduct: [],
      asked_discountprice: 0,
      asked_discountpercent: 0,
    };
  }

  componentDidMount() {
    this.setCartDetails();
  }

  setCartDetails = () => {
    const {
      cart,
      user,
      match: {
        params: { id },
      },
    } = this.props;
    const product = cart.find((item) => item.id === id && item.userId === user.id) || {};
    this.setState({ updatedProduct: { ...product }, asked_discountpercent: product.asked_discountpercent, asked_discountprice: product.asked_discountprice, reason: product.reason });
  };

  calculationLogic = () => {
    const { updatedProduct, asked_discountpercent, asked_discountprice, reason } = this.state;
    const discountPercent = Number(asked_discountpercent) / 100;
    const discountprice = updatedProduct.discounted_price * discountPercent;
    const discountedPrice = updatedProduct.discounted_price - discountprice;
    const discountpriceOnListPrice = updatedProduct.price * discountPercent;
    const discountedPriceOnListPrice = updatedProduct.price - discountpriceOnListPrice;
    const finalDiscountedPrice = Number(asked_discountpercent) > 0 ? (discountedPrice > 0 ? discountedPrice : discountedPriceOnListPrice) : 0;
    const finalCustomPrice = Number(asked_discountprice) > 0 ? Number(asked_discountprice) : 0;
    updatedProduct.reason = reason;
    updatedProduct.asked_discountpercent = Number(asked_discountpercent);
    updatedProduct.asked_discountprice = Number(asked_discountprice);
    updatedProduct.asked_price = Number(asked_discountprice) > 0 ? finalCustomPrice : Number(asked_discountpercent) > 0 ? finalDiscountedPrice : 0;
    updatedProduct.sub_total = Number(updatedProduct.asked_price) > 0 ? Number(updatedProduct.asked_price) * updatedProduct.quantity : updatedProduct.discounted_price > 0 ? updatedProduct.discounted_price * updatedProduct.quantity : updatedProduct.price * updatedProduct.quantity;
    this.setState({ updatedProduct });
  };

  onInputChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => {
      this.calculationLogic();
    });
    // if (name === 'reason' && value.length > 50) {
    //   document.getElementById('reasonLengthError').innerHTML = 'Maximum 50 characters are allowed';
    // } else {
    //   document.getElementById('reasonLengthError').innerHTML = '';
    // }
    if (name === 'asked_discountpercent' && value > 99) {
      document.getElementById('maxPercentError').innerHTML = 'Maximum discount can be 99 (%)';
    } else {
      document.getElementById('maxPercentError').innerHTML = '';
    }
  };

  onSubmit = async (event) => {
    const { updatedProduct } = this.state;
    const { cart, user, history, dispatch } = this.props;
    const { id } = user;
    event.preventDefault();
    Swal({
      title: 'Confirm',
      text: `Are you sure you want to update product with this price?`,
      icon: 'success',
      buttons: true,
      modal: false,
    }).then(async (status) => {
      const filteredCart = filter(cart, (item) => item.userId === id);
      const otherCartItems = filter(cart, (item) => item.userId !== id);
      if (status) {
        const updatedCart = filteredCart.map((item) => {
          if (item.id === updatedProduct.id) {
            return updatedProduct;
          }
          return item;
        });
        const finalCart = [...otherCartItems, ...updatedCart];
        dispatch({ type: 'CART', payload: finalCart });
        toast.success('Your product has been updated successfully.');
        history.push('/cart');
      } else {
        const finalCart = [...otherCartItems, ...filteredCart];
        this.resetData(finalCart);
      }
    });
  };

  resetData = (cart) => {
    const { dispatch } = this.props;
    dispatch({ type: 'CART', payload: cart });
  };

  render() {
    const { cart, match, user } = this.props;
    const filteredCart = filter(cart, (item) => item.userId === user?.id);
    const { updatedProduct } = this.state;
    const product = updatedProduct;
    const price = product && Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(product.price);
    const DiscountedPrice = product && Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(product.discounted_price);
    let finalPrice = Number(updatedProduct.asked_price) > 0 ? Number(updatedProduct.asked_price) * updatedProduct.quantity : product.discounted_price > 0 ? product.discounted_price * product.quantity : product.price * product.quantity;
    finalPrice = Intl.NumberFormat('en-ph', { style: 'currency', currency: 'php' }).format(finalPrice);
    return (
      <>
        <div className="container mt-2">
          <Header section={`Overview of product ${match.params.id}`} title={product?.name || ''}>
            <Link to="/cart" className="btn btn-outline-secondary" onClick={() => this.resetData(filteredCart)}>
              Cancel
            </Link>
            <button type="button" className="btn btn-merck-pink ml-2" onClick={this.onSubmit}>
              Save
            </button>
          </Header>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-4">
                        <div className="form-group">
                          <label>List Price</label>
                          <input disabled className="form-control" value={price} style={{ backgroundColor: '#F1F2F3' }} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label>Discounted Price</label>
                          <input disabled className="form-control" value={DiscountedPrice} style={{ backgroundColor: '#F1F2F3' }} />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group">
                          <label>SubTotal</label>
                          <input disabled className="form-control" value={finalPrice} style={{ backgroundColor: '#F1F2F3' }} />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Asked Discount (%)</label>
                          <input disabled={product.asked_discountprice} value={product.asked_discountprice ? '' : product.asked_discountpercent || ''} className="form-control" id="asked_discountpercent" name="asked_discountpercent" placeholder="e.g. 5 " onChange={this.onInputChange} style={{ backgroundColor: product.asked_discountprice > 0 ? '#F1F2F3' : '' }} />
                          <span id="maxPercentError" className="error-text text-danger" />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label>Asked Price (Per Quantity)</label>
                          <input disabled={Number(product.asked_discountpercent)} value={Number(product.asked_discountpercent) > 0 ? '' : Number(product.asked_discountprice) || ''} className="form-control" id="asked_discountprice" name="asked_discountprice" placeholder="e.g. 2000" onChange={this.onInputChange} style={{ backgroundColor: product.asked_discountpercent > 0 ? '#F1F2F3' : '' }} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Reason</label>
                          {/* <input className="form-control" id="reason" name="reason" value={product?.reason || ''} placeholder="Please enter reason for requesting discount" onChange={this.onInputChange} /> */}
                          <textarea type="text" id="reason" name="reason" className="form-control" value={product?.reason || ''} placeholder="Please enter reason for requesting discount" multiple onChange={this.onInputChange} rows={3} />
                          {/* <span id="reasonLengthError" className="error-text text-danger" /> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
  };
};

export default connect(mapStateToProps)(AppCartEdit);
