import React from 'react';

export const DataModal = ({ product }) => {
  const { distributor_note, customer_note } = product;
  return (
    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: '400px' }}>
      <div className="modal-content">
        <div className="modal-header" style={{ maxHeight: '20px' }}>
          <h3 className="modal-title text-merck-yellow" id="exampleModalCenterTitle">
            Requirements to purchase the product
          </h3>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h4 className="header-title">Distributor Requirement: </h4>
          <br />
          {distributor_note}
          <hr />
          <h4 className="header-title">End User Requirement: </h4>
          <br />
          {customer_note}
        </div>
        <div className="modal-footer" style={{ maxHeight: '40px' }}>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
