import React from 'react';
import swal from 'sweetalert';
import map from 'lodash/map';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import ContentContainer from '../../admin/components/ContentContainer';
import { confirmBox, addDefaultSrc } from '../../admin/constants/Constants';
import { getAllUsers, deleteUserById, activateUser, deactivateUser } from '../../services';

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      keyword: '',
      active: false,
      loading: false,
      sortDirection: 'asc',
      filteredUsers: false,
    };
  }

  componentDidMount() {
    this.getUsersList();
  }

  doLogout = async () => {
    await this.props.dispatch({ type: 'USER', payload: {} });
    await localStorage.removeItem('token');
    window.location.href = '/admin';
  };

  getUsersList = async () => {
    this.setState({ loading: true });
    const { active } = this.state;
    try {
      const response = await getAllUsers();
      if (response) {
        if (response.status === 200) {
          const usersList = response.data.filter((item) => item.active === active);
          this.setState({ users: usersList || [], loading: false });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            this.doLogout();
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  onSearch = async ({ target: { value } }) => {
    const { users } = this.state;
    const search = value.toLowerCase().trim().replace(/ /g, '');
    this.setState({
      keyword: value,
      filteredUsers: !value
        ? false
        : users.filter((item) => {
            return item.name.toLowerCase().trim().replace(/ /g, '').includes(search) || item.company.toLowerCase().trim().replace(/ /g, '').includes(search);
          }),
    });
  };

  toggleUsers = (active) => {
    this.setState({ active, filteredUsers: false, keyword: '' }, () => {
      this.getUsersList();
    });
  };

  toggleSortDirection = async () => {
    const { sortDirection } = this.state;
    await this.setState({ sortDirection: sortDirection === 'asc' ? 'desc' : 'asc' });
    this.getUsersList();
  };

  changeUserStatus = async (user) => {
    swal({
      title: `Confirm`,
      text: user.active === false ? `Are you sure that you want to activate this user? Please Make sure you have reviewed user information.` : 'Are you sure that you want to deactivate this user?',
      icon: user.active === false ? 'success' : 'warning',
      dangerMode: user.active === false ? false : true,
      buttons: ['Cancel', 'Yes'],
      modal: false,
    }).then(async (status) => {
      if (status) {
        let isActive = user.active ? false : true;

        const updatedUserData = {
          active: isActive,
        };
        const response = isActive ? await activateUser(user.id, updatedUserData) : await deactivateUser(user.id, updatedUserData);
        try {
          if (response) {
            if (response.status === 200 || response.status === 201) {
              await toast.success(`${user.name}'s account has been ${user.active === false ? 'Activated' : 'Deactivated'}`);
              this.setState({ loading: false, filteredUsers: false, keyword: '', active: isActive }, () => {
                this.getUsersList();
              });
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                this.doLogout();
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
      }
    });
  };

  deleteUser = async (user) => {
    confirmBox(`Are you sure that you want to delete this user?`).then(async (status) => {
      if (status) {
        this.setState({ loading: true });
        const response = await deleteUserById(user.id);
        try {
          if (response) {
            if (response.status === 200 || response.status === 201) {
              toast.success(`${user.name}'s account has been deleted successfully`);
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                this.doLogout();
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
        this.setState({ loading: false, filteredUsers: false }, () => {
          this.getUsersList();
        });
      }
    });
  };

  render() {
    const { active, users, loading, filteredUsers, keyword } = this.state;
    const userData = filteredUsers ? filteredUsers : users;
    const isViewRole = this.props.user.specialRole;
    return (
      <>
        <Spinner display={loading}>
          <div className="container mt-4">
            <ul className="nav nav-tabs mb-4">
              <li className="nav-item">
                <div className={active ? 'nav-link' : 'nav-link active'} style={{ cursor: 'pointer' }} onClick={() => this.toggleUsers(false)}>
                  Pending
                </div>
              </li>
              <li className="nav-item">
                <div className={active ? 'nav-link active' : 'nav-link'} style={{ cursor: 'pointer' }} onClick={() => this.toggleUsers(true)}>
                  Active
                </div>
              </li>
            </ul>

            <div className="card">
              <div className="card-header">
                <form>
                  <div className="input-group input-group-flush">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fe fe-search"></i>
                      </span>
                    </div>
                    <input
                      value={keyword}
                      className="form-control search"
                      type="search"
                      placeholder="Search by name/company..."
                      onChange={this.onSearch}
                      onKeyPress={(e) => {
                        e.key === 'Enter' && e.preventDefault();
                      }}
                    />
                  </div>
                </form>
              </div>
              <ContentContainer size={userData && userData.length} isKeywordAndNoDataFound={!userData.length && keyword}>
                <div className="table-responsive">
                  <table className="table table-sm table-nowrap card-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th data-sort="orders-product" style={{ cursor: 'pointer' }} onClick={() => this.toggleSortDirection()}>
                          Name
                        </th>
                        <th>Company</th>
                        <th>City</th>
                        <th>Province</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {userData &&
                        map(userData, (user, index) => (
                          <tr key={index}>
                            <td>
                              <a href="#0" className="avatar avatar-lg">
                                <img onError={addDefaultSrc} src={user.photo_url ? user.photo_url : '/img/default-user.png'} alt={user.name} className="avatar-img rounded-circle" />
                              </a>
                            </td>
                            <td>
                              <h4 className="mb-1 text-capitalize"> {user.name}</h4>
                              <p className="small text-muted mb-0">{user.email}</p>
                            </td>
                            <td>{user.company}</td>
                            <td style={{ maxWidth: '260px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} data-toggle="tooltip" title={user.address.city}>
                              {user.address.city}
                            </td>
                            <td>{user.address.state}</td>
                            <td className="text-right">
                              <>
                                <div className="dropdown">
                                  <div className="dropdown-ellipses dropdown-toggle text-muted" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false" aria-expanded="false">
                                    <i className="fe fe-more-vertical"></i>
                                  </div>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    {!isViewRole && (
                                      <div
                                        className="dropdown-item cu"
                                        onClick={() => {
                                          this.changeUserStatus(user);
                                        }}
                                      >
                                        {active ? <i className="fe fe-slash">Deactivate</i> : <i className="fe fe-check-circle">Activate</i>}
                                      </div>
                                    )}
                                    <hr />
                                    <Link to={`/admin/users/${user.id}/edit`} className="dropdown-item">
                                      <i className="fe fe-edit"></i> Edit
                                    </Link>
                                    {!isViewRole && (
                                      <div
                                        className="dropdown-item text-danger"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                          this.deleteUser(user);
                                        }}
                                      >
                                        <i className="fe fe-trash"></i> Delete
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </ContentContainer>
            </div>
          </div>
        </Spinner>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(AdminUsers);
