import React from 'react';
import Swal from 'sweetalert';
import { filter } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner';
import SimpleReactValidator from 'simple-react-validator';
import { trackPageView, trackEvent } from '../../helpers/analytics';
import { createQuote, getAddressById, addAddress, deleteAddress } from '../../services';

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      state: '',
      zipCode: '',
      address1: '',
      addresses: [],
      loading: false,
      userAddresses: [],
      cityClass: 'is-invalid',
      codeClass: 'is-invalid',
      stateClass: 'is-invalid',
      addressClass: 'is-invalid',
      isContinueDisabled: false,
    };

    this.validator = new SimpleReactValidator();
  }

  componentDidMount = async () => {
    this.getUserAddresses();
    this.getAddresses();
    trackPageView();
  };

  getUserAddresses = async () => {
    const { user } = this.props;
    const { address } = user;
    this.setState({ userAddresses: [address] });
  };

  getAddresses = async () => {
    const { user, dispatch, history } = this.props;
    const { id } = user;
    this.setState({ loading: true });
    try {
      const response = await getAddressById(id);
      if (response) {
        if (response.status === 200) {
          this.setState({
            addresses: response.data,
            loading: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  onInputChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value });
    switch (event.target.name) {
      case 'address1':
        await this.setState({ addressClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid' });
        if (this.state.addressClass === 'is-invalid') {
          this.validator.showMessages('address1');
          this.forceUpdate();
        }
        break;

      case 'city':
        await this.setState({ cityClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid' });
        if (this.state.cityClass === 'is-invalid') {
          this.validator.showMessages();
          this.forceUpdate();
        }
        break;

      case 'state':
        await this.setState({ stateClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid' });
        if (this.state.stateClass === 'is-invalid') {
          this.validator.showMessages();
          this.forceUpdate();
        }
        break;

      case 'zipCode':
        await this.setState({ codeClass: event.target.value.length <= 0 ? 'is-invalid' : 'is-valid' });
        if (this.state.codeClass === 'is-invalid') {
          this.validator.showMessages();
          this.forceUpdate();
        }
        break;

      default:
        break;
    }
  };

  addAddress = async (e) => {
    e.preventDefault();
    trackEvent('Address', 'Add Address');
    const { user, dispatch, history } = this.props;
    const { id } = user;
    if (this.state.addressClass === 'is-valid' && this.state.cityClass === 'is-valid' && this.state.stateClass === 'is-valid') {
      let address = {
        address1: this.state.address1,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.zipCode,
        user_id: id,
      };
      this.setState({ isContinueDisabled: true });
      const response = await addAddress(id, address);
      if (response) {
        if (response.status === 200 || response.status === 201) {
          await this.getAddresses();
          await this.submitQuote(address);
          await this.setState({
            address1: '',
            state: '',
            city: '',
            zipCode: '',
            addressClass: '',
            stateClass: '',
            cityClass: '',
            codeClass: '',
            isContinueDisabled: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } else {
      this.validator.showMessages();
      toast.error('Please fill all the Mandatory details');
      this.setState({ isContinueDisabled: false });
      this.forceUpdate();
    }
  };

  removeAddress = (addressId) => {
    const { user, dispatch, history } = this.props;
    const { id } = user;
    trackEvent('Address', 'Remove Address');
    Swal({
      text: `Are you sure that you want to remove this address?`,
      icon: 'error',
      dangerMode: true,
      buttons: true,
      modal: false,
    }).then(async (status) => {
      if (status) {
        const response = await deleteAddress(id, addressId);
        try {
          if (response) {
            if (response.status === 200 || response.status === 201) {
              toast.success('address has been removed.');
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/');
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
        await this.getAddresses();
      }
    });
  };

  submitQuote = async (address) => {
    const {
      user,
      cart,
      distributor,
      // match: {
      //   params: { type },
      // },
      history,
      dispatch,
    } = this.props;
    const { id } = user;
    const updatedCart = filter(cart, (item) => item.userId === id);
    trackEvent('Address', 'Request Quote');
    Swal({
      title: 'Confirm quote request',
      text: `Are you sure you want to submit the request?`,
      icon: 'success',
      buttons: true,
      modal: false,
    }).then(async (status) => {
      if (status) {
        this.setState({ loading: true });
        let products = [];
        updatedCart.forEach((item) => {
          delete item.asked_discountpercent;
          delete item.asked_discountprice;
          item.comment = '';
          item.approved = Number(item.asked_price) > 0 ? false : true;
          item.action_taken = Number(item.asked_price) > 0 ? false : true;
          products.push(item);
        });

        const isAdminApprovalRequired = (item) => Number(item.asked_price) <= 0 && item.approved === true;
        const isClosed = products.every(isAdminApprovalRequired);
        const subTotal = updatedCart.reduce((subTotal, product) => subTotal + product.sub_total, 0);
        // const freight = type === 'Delivery' ? (0.5 / 100) * subTotal : type === 'Pick-Up' ? (0.15 / 100) * subTotal : 0;
        // const totalForTax = subTotal + freight;
        // const total = subTotal + freight + tax;
        const totalForTax = subTotal + 0;
        const tax = (12 / 100) * totalForTax;
        const total = subTotal + 0 + tax;
        let quote = {
          customer: user,
          distributor: distributor,
          products: products,
          address: address,
          status: isClosed ? 'Closed' : 'Open',
          sub_total: subTotal,
          request_type: '',
          freight: 0,
          tax: tax,
          total: total,
        };
        const response = await createQuote(quote);
        try {
          if (response) {
            if (response && response.status === 201) {
              const { id } = response.data;
              toast.info(`Quote Request #${id} has been created and sent to ${quote.distributor.name}`);
              this.setState({ loading: false });
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/');
              }
              this.setState({ loading: false });
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          }
        } catch (error) {
          console.warn(error);
        }
        history.push('/quotes');
        localStorage.removeItem('request');
        const latestCart = filter(cart, (item) => item.userId !== id);
        await dispatch({ type: 'CART', payload: latestCart });
      }
    });
  };

  render() {
    const { loading, isContinueDisabled, addresses, userAddresses, address1, city, state, zipCode, stateClass, addressClass, codeClass, cityClass } = this.state;
    return (
      <Spinner display={loading}>
        <div className="container mt-5">
          <div className="text-center mb-4">
            <h2>Select a delivery address</h2>
          </div>

          <div className="row card-deck">
            {userAddresses.map((address, index) => (
              <div className="col-md-6 col-lg-4 mb-3" key={index}>
                <div className="card h-100">
                  <div className="card-body">
                    <p className="card-text">
                      {address.address1} <br />
                      {address.city}, {address.state} {address.postal_code}
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <button className="btn btn-sm btn-success m-2" onClick={() => this.submitQuote(address)}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row card-deck">
            {addresses.map((address, index) => (
              <div className="col-md-6 col-lg-4 mb-3" key={index}>
                <div className="card h-100">
                  <div className="card-body">
                    <p className="card-text">
                      {address.address1} <br />
                      {address.city}, {address.state} {address.postal_code}
                    </p>
                  </div>
                  <div className="card-footer d-flex">
                    <button className="btn btn-sm btn-success m-2" onClick={() => this.submitQuote(address)}>
                      Continue
                    </button>
                    {address.hasOwnProperty('id') && (
                      <button className="btn btn-sm btn-light m-2" onClick={() => this.removeAddress(address.id)}>
                        Remove
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="card mt-3">
            <div className="card-header">
              <h3 className="card-title">Add new delivery address</h3>
            </div>
            <div className="card-body">
              <form onSubmit={this.addAddress}>
                <div className="form-group">
                  <label>Office Address</label>
                  <span className="text-merck-pink">*</span>
                  <input type="text" className={`form-control ${addressClass}`} id="registeredAddress" name="address1" placeholder="e.g. Belman Building II, 78 Cordillera Street corner QuezonAvenue, Quezon City" onChange={this.onInputChange} value={address1} />
                  <span className="text-merck-pink">{this.validator.message('Address', address1, 'required')}</span>
                </div>
                <div className="form-group">
                  <label>City</label>
                  <span className="text-merck-pink">*</span>
                  <input type="text" className={`form-control ${cityClass}`} name="city" id="city" placeholder="e.g. Quezon" onChange={this.onInputChange} value={city} />
                  <span className="text-merck-pink">{this.validator.message('City', city, 'required')}</span>
                </div>
                <div className="form-group">
                  <label>Province</label>
                  <span className="text-merck-pink">*</span>
                  <input type="text" className={`form-control ${stateClass}`} id="state" name="state" placeholder="e.g. Manila" onChange={this.onInputChange} value={state} />
                  <span className="text-merck-pink">{this.validator.message('state', state, 'required')}</span>
                </div>
                <div className="form-group">
                  <label>Postal Code</label>
                  <span className="text-danger">*</span>
                  <input type="text" maxLength="4" className={`form-control ${codeClass}`} id="zipCode" name="zipCode" placeholder="e.g. 1100" onChange={this.onInputChange} value={zipCode} />
                  <span className="text-merck-pink">{this.validator.message('zipCode', zipCode, 'required')}</span>
                </div>

                <button type="submit" className="btn btn-success" disabled={isContinueDisabled}>
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </Spinner>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
    distributor: state.distributor,
  };
};

export default connect(mapStateToProps)(Address);
