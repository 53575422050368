import saveAs from 'file-saver';
import html2canvas from 'html2canvas';

import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert';
import { ROLES, roles } from '../../admin/constants/Constants';
import Amount from '../../components/Amount';
import Spinner from '../../components/Spinner';
import { trackEvent, trackPageView } from '../../helpers/analytics';
import { endorseSuperAdmin, getQuoteById, updateQuoteById } from '../../services';

const moment = require('moment');

const xl = require('excel4node');
const XLSX = require('xlsx');
const cartHelper = require('../../helpers/appHelper');

window.html2canvas = html2canvas;

class QuoteView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      products: [],
    };
  }

  componentDidMount() {
    trackPageView();
    this.getQuoteDetails();
  }

  getQuoteDetails = async () => {
    this.setState({
      loading: true,
    });
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    try {
      const response = await getQuoteById(id);
      if (response) {
        if (response.status === 200) {
          this.setState({
            quote: response.data,
            loading: false,
          });
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          this.setState({ loading: false });
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
      this.setState({ loading: false });
    }
  };

  submitQuote = async (e) => {
    e.preventDefault();
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    Swal({
      title: `Confirm`,
      text: ` Are you sure you wish to submit? You will not able to un-do the changes once confirmed.`,
      icon: 'success',
      dangerMode: false,
      buttons: ['Cancel', 'Yes'],
      modal: false,
    }).then((status) => {
      if (status) {
        this.setState({ loading: true }, async () => {

          const { quote } = this.state;
          const updateProductData = {
            status: 'Open-Closed',
            products: quote.products,
          };
          const response = await updateQuoteById(id, updateProductData);
          try {
            if (response && (response.status === 200 || response.status === 201)) {
              this.setState({ loading: false });
              toast.success(`Your quote ${id} has been Reviewed`);
              await history.push('/admin/quotes');
            } else {
              if (response.response.status === 401 || response.response.data === 'Unauthorized') {
                toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
                await dispatch({ type: 'USER', payload: {} });
                await localStorage.removeItem('token');
                history.push('/admin');
              }
              if (response.response.status >= 500) {
                toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
              }
            }
          } catch (error) {
            console.warn(error);
          }
        });
      }
    });
    trackEvent('Cart', 'Submit Quote');
  };

  sendNotification = async (event) => {
    const {
      match: {
        params: { id },
      },
      dispatch,
      history,
    } = this.props;
    event.preventDefault();
    const notification = {
      superAdminEmail: true,
    };
    const response = await endorseSuperAdmin(id, notification);
    try {
      if (response) {
        if (response.status === 200 || response.status === 201) {
          this.setState({ loading: false });
          toast.success('Notification has been sent to super-admin for approval');
        } else {
          if (response.response.status === 401 || response.response.data === 'Unauthorized') {
            toast.error('Sorry your session has expired you need to Re-Login to access the Application.');
            await dispatch({ type: 'USER', payload: {} });
            await localStorage.removeItem('token');
            history.push('/admin');
          }
          if (response.response.status >= 500) {
            toast.error('Oops! There was an error trying to process your request. Please try again or contact admin.');
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  };

  downloadTemplate = async () => {
    let wb = new xl.Workbook();
    let ws = wb.addWorksheet('template');
    ws.column(1).setWidth(20);
    ws.column(2).setWidth(20);
    ws.cell(1, 1).string('Product Id');
    ws.cell(1, 2).string('Approval Price');
    ws.cell(1, 3).string('Approval Price Comment');
    ws.cell(1, 4).string('Comment');
    wb.writeToBuffer().then(function (buffer) {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Template.xlsx');
    });
    trackEvent('Cart', 'Download Template');
  };

  bulkUpload = async (e, name) => {
    e.preventDefault();
    const { dispatch } = this.props;
    const files = e.target.files;
    const file = files[0];
    let reader = new FileReader();
    if (FileReader.prototype.readAsBinaryString === undefined) {
      FileReader.prototype.readAsBinaryString = function (fileData) {
        let binary = '';
        let pt = this;
        let reader = new FileReader();
        reader.onload = function (e) {
          let bytes = new Uint8Array(reader.result);
          let length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          pt.content = binary;
          pt.onload();
        };
        reader.readAsArrayBuffer(fileData);
      };
    }
    reader.onload = async (e) => {
      const target = !e ? reader.content : e.target.result;
      let template = XLSX.read(target, { type: 'binary' });
      const sheet = template.Sheets[template.SheetNames[0]];
      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      let cart = [];
      const { quote, loading } = this.state;
      if (data[0][0] === 'Product Id' && data[0][1] === 'Approval Price') {
        // if(quote.products)

        for (let i = 1; i < data.length; i++) {
          const [id, newApprovedPrice, newApprovedPriceComment, newComment] = data[i];
          const cleanedId = id.toString().trim();

          const quoteItem = quote.products.find((item) => item.id === cleanedId);
          if (quoteItem?.asked_price) {
            quoteItem.action_taken = false;
            if (quoteItem && quoteItem?.limit_price < newApprovedPrice) {
              quoteItem.action_taken = true;
            }
            if (quoteItem) {
              quoteItem.approved_price = newApprovedPrice;
              quoteItem.sub_total = newApprovedPrice;
              quoteItem.approved_price_comment = newApprovedPriceComment;
              quoteItem.comment = newComment;

            }
          }
        }
        this.setState({
          quote: quote,
          loading: false,
        });
        const updateProductData = {
          products: quote.products,
        };
        const response = await updateQuoteById(quote.id, updateProductData);
        dispatch({ type: 'CART', payload: cart });
      } else {
        toast.error('Oops! Could not import the file as it is not in a valid format. Please click on Download Template to review the file format');
      }
    };
    reader.readAsBinaryString(file);
    trackEvent('Cart', 'Import Template');
  };

  render() {
    const { user } = this.props;
    const { quote, loading } = this.state;

    const Date = quote ? moment(quote.created_on).format('LLL') : '';
    const isAdminApprovalRequired = (item) => item.action_taken === true;
    const isReivewed = quote && quote.products.every(isAdminApprovalRequired);
    const isEndorseRequired = (item) => (Number(item.asked_price) > 0 && Number(item.asked_price) < Number(item.limit_price)) || (Number(item.asked_price) > 0 && Number(item.limit_price) <= 0);
    const isEndorse = quote && quote.products.some(isEndorseRequired);
    const subTotal = quote && quote.products.reduce((subTotal, product) => subTotal + product.sub_total, 0);
    // const subTotal =
    //   quote &&
    //   quote.products.reduce((subTotal, product) => {
    //     return product.approved_price !== 0 ? subTotal + product.approved_price : subTotal + product.sub_total;
    //   }, 0);

    const totalForTax = subTotal + 0;
    const tax = (12 / 100) * totalForTax;
    const total = subTotal + 0 + tax;
    return (
      <Spinner display={loading}>
        {quote && (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-11">
                <div className="header mt-md-5">
                  <div className="header-body">
                    <div className="row align-items-center">
                      <div className="col">
                        <h6 className="header-pretitle">Quote Request</h6>
                        <h1 className="header-title">{quote.id}</h1>
                      </div>
                      <div className="col-auto">
                        <div className="mt-5 text-center d-flex">
                          {!user.specialRole && !Number(quote.approved_price) > 0 && quote.status === 'Open' ? (
                            <>
                              <button className="btn btn-light mb-2" type="button" onClick={() => this.downloadTemplate()}>
                                <i className="fe fe-download"></i> <span className="d-none d-md-none d-lg-none d-xl-inline">Download Template</span>
                              </button>
                              <div className="custom-file-upload mr-2">
                                <input
                                  type="file"
                                  value=""
                                  accept=".xlsx"
                                  onChange={(e) => {
                                    this.bulkUpload(e, 'uploadFile');
                                  }}
                                  style={{ overflow: 'hidden' }}
                                />
                                <button
                                  onClick={(e) => {
                                    this.bulkUpload(e, 'uploadFile');
                                  }}
                                  className="btn btn-light ml-2 mb-2"
                                >
                                  <i className="fe fe-upload"></i> <span className="d-none d-md-none d-lg-none d-xl-inline">Bulk Upload</span>
                                </button>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                          <div>
                            <Link to="/admin/quotes" className="btn btn-outline-secondary">
                              Close
                            </Link>
                            {!user.specialRole && quote.status === 'Open' && isEndorse && user.hasOwnProperty(ROLES) && user.roles.includes(roles.ADMIN_USER) && (
                              <button type="button" className="btn btn-secondary ml-2" data-toggle="tooltip" data-placement="top" onClick={this.sendNotification}>
                                Endorse
                              </button>
                            )}
                            {!user.specialRole && quote.status === 'Open' && (
                              <button disabled={!isReivewed} className="btn btn-success ml-2" type="button" onClick={this.submitQuote}>
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="nodeForPdf" className="col-12 col-md-11" style={{ fontSize: '13px' }}>
                <div className="card card-body p-5">
                  <div className="col text-center">
                    <h2 className="mb-2">Quotation #{quote.id}</h2>
                  </div>
                  <div className="row my-5">
                    <div className="col-12 col-md-6 order order-md-2 pl-md-5">
                      {/* <div>
                        <h4 style={{ fontSize: '14px', fontWeight: 'bold' }}>Request Type:</h4>
                        <p> {quote.request_type}</p>
                      </div> */}
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Ship To:</p>
                        {quote.address.address1} <br />
                        {`${quote.address.city}, ${quote.address.state}`} <br />
                        {`${quote.address.postal_code}`} <br />
                      </div>
                    </div>
                    <div className="col-12 col-md-6  order order-md-1 pr-md-5">
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: 'bold' }}>Customer: {quote.customer.customerId}</p>
                        Name: {`${quote.customer.name}`} <br />
                        {quote.customer.company} <br />
                        {quote.customer.address.address1} <br />
                        {`${quote.customer.address.city}, ${quote.customer.address.state}`} <br />
                        {`${quote.customer.address.postal_code}`} <br />
                        Email: {`${quote.customer.email}`} <br />
                      </div>
                    </div>
                  </div>

                  <div className="my-5">
                    <p style={{ fontSize: '14px', fontWeight: 'bold' }}>{`Quotation Request No. ${quote.id} Dated ${Date}`}</p>
                  </div>

                  <div className="d-flex justify-content-between mt-5">
                    <div>
                      Dear Valued Customer, <br />
                      We are pleased to offer you the following item(s) as per your request
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table mb-4">
                          <thead>
                            <tr>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">No.</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">Product Name</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">Quantity</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0 text-center">
                                <span className="h6">List Price</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">Discounted Price</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">Asked Price</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0">
                                <span className="h6">Approved Price</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0 text-center">
                                <span className="h6">Total</span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0 text-right">
                                <span className="h6"> </span>
                              </th>
                              <th className="px-0 bg-transparent border-top-0 text-left">
                                <span className="h6"> </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {quote.products.map((product, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td className="px-0">
                                    {product.id}
                                    {product.cell_line && (
                                      <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={(props) => {
                                          return (
                                            <Tooltip id="button-tooltip" {...props}>
                                              Cell line product
                                            </Tooltip>
                                          );
                                        }}
                                      >
                                        <i className="ms-2 fe fe-info" style={{ fontSize: '14px' }}></i>
                                      </OverlayTrigger>
                                    )}
                                    <br />
                                    {product.name}
                                  </td>
                                  <td className="px-0">{product.quantity}</td>
                                  <td className="px-0 text-center">
                                    <Amount value={product.price} />
                                  </td>
                                  <td className="px-0 text-center">
                                    <Amount value={product.discounted_price} />
                                  </td>
                                  <td className="px-0 text-center">{product.asked_price ? <Amount value={product.asked_price} /> : '-'}</td>
                                  <td className="px-0 text-center">{product.approved_price ? <Amount value={product.approved_price} /> : '-'}</td>
                                  <td className="px-0 text-right">
                                    <Amount value={product.sub_total} />
                                  </td>
                                  {!user.specialRole && Number(product.asked_price) > 0 && quote.status === 'Open' ? (
                                    <td className="text-right">
                                      <>
                                        <Link to={`/admin/quotes/${this.props.match.params.id}/edit/${product.id}`} className={product.action_taken ? 'btn btn-sm btn-outline-success' : 'btn btn-sm btn-outline-info'}>
                                          Review
                                        </Link>
                                      </>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-12 col-md-8"></div>
                    <div className="col-12 col-md-4 text-right">
                      <div className="row d-flex justify-content-between">
                        <div className="col-6 text-left" style={{ fontWeight: 'bold' }}>
                          Sub Total <br />
                          {/* Freight
                          <br /> */}
                          Tax
                          <br />
                          Grand Total
                        </div>
                        <div className="col-6">
                          <Amount value={subTotal} />
                          <br />
                          {/* <Amount value={freight} />
                          <br /> */}
                          <Amount value={tax} />
                          <br />
                          <Amount value={total} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="my-5" />
                  <h6 className="text-uppercase" style={{ fontWeight: 'bold' }}>
                    Note:
                  </h6>
                  <p className="text-muted mb-0">We really appreciate your business and if there’s anything else we can do, please let us know!</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Spinner>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(QuoteView);
